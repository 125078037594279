import { bindable } from 'aurelia-framework';
import { ProductionService } from 'services/production-service';

export class PolicySummarySplits {
    static inject = [ProductionService];
    _production;

    @bindable policyId;

    constructor(production) {
        this._production = production;
    }

    policyIdChanged() {
        this._load();
    }

    async _load() {
        try {
            if (!this.policyId) return;
            this.splits = await this._production.policySplits(this.policyId);
        } catch (err) {
            console.log(err);
        }
    }
}
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ProductionService } from 'services/production-service';
import { c } from 'common/common';

export class PolicyMiniSummary {
    static inject = [Element, EventAggregator, ProductionService];
    @bindable policyId;
    _element;
    _production;
    _ea;

    constructor(element, ea, production) {
        this._element = element;
        this._ea = ea;
        this._production = production;
    }

    attached() {
        this._load();
    }

    async _load() {
        if (!this.policyId) return;
        try {
            this.policy = await this._production.policy(this.policyId);
        } catch (err) {
            console.log(err);
        }
    }

    closeSummary() {
        this._element.dispatchEvent(new CustomEvent('close', { bubbles: true, detail: {} }));
    }

    openProfile(event, member) {
        if (event) event.stopPropagation();
        this._ea.publish(c.EventKeys.site.openProfile, { memberId: member.id });
        return false;
    }

    noteUpdated() {
        this._element.dispatchEvent(new CustomEvent('noteupdated', { bubbles: true, detail: {} }));
    }
}

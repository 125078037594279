import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ProductionService } from 'services/production-service';
import { c } from 'common/common';

export class PolicySummary {
    static inject = [EventAggregator, ProductionService];
    @bindable policyId;
    @bindable policy;
    @bindable showPolicyNumberIcon = false;
    _production;
    _ea;

    constructor(ea, production) {
        this._ea = ea;
        this._production = production;
    }

    attached() {
        this._load();
    }

    async _load() {
        if (!this.policyId && !this.policy) return;
        try {
            if (this.policyId) {
                this.policy = await this._production.policy(this.policyId);
            }
        } catch (err) {
            console.log(err);
        }
    }

    openProfile(event, member) {
        if (event) event.stopPropagation();
        this._ea.publish(c.EventKeys.site.openProfile, { memberId: member.id });
        return false;
    }

    hasCredit(value) {
        if (value === undefined || value === null) return false;
        return true;
    }

    async switchSplit() {
        try {
            const splits = await this._production.policySplits(this.policy.id);
            if (!splits || !splits.length) return;
            this.policyId = splits[0].id;
            this._load();
        } catch (err) {
            console.log(err);
        }
    }
}

import { bindable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ProductionService } from 'services/production-service';
import { Security } from 'common/security';
import { Notes } from 'services/notes';
import { c } from 'common/common';
import moment from 'moment';
import { Capacitor } from '@capacitor/core';

export class PolicyList {
    static inject = [EventAggregator, ProductionService, Security, Notes];
    @bindable policies;
    @bindable policyPremiumType = 'submitted';
    @bindable scroll = false;
    @bindable policyListType = 'submitted';
    @bindable policyStatuses;
    @bindable showName = 'client';
    @bindable pageSize = 10;
    @bindable delayLoad = false;
    @bindable finalColumn = 'note';
    @bindable theme = '';
    @bindable dateType = 'submitted';

    _ea;
    _productionService;
    _security;
    _notes;
    showDrawer = false;
    policyNote;
    showNoneFound = true;

    policyFilters = [{ value: '', keys: ['submittedDate', 'client', 'writingAgent.fullName', 'policyNumber', 'carrier', 'carrierCode', 'submittedPremium', 'statusName', 'client'] }];

    constructor(ea, productionService, security, notes) {
        this._ea = ea;
        this._productionService = productionService;
        this._security = security;
        this._notes = notes;
    }

    policiesChanged() {
        if (!this.policies) {
            this.policies = [];
            if (!this.delayLoad) this.loading = true;
            return;
        }
        this.loading = false;
        this.showNoneFound = true;
    }
    
    delayLoadChanged() {
        if (this.loading && this.delayLoad) {
            this.loading = false;
            this.showNoneFound = false;
        }
    }

    @computedFrom('policy', 'policyPremiumType')
    get policyPremium() {
        if (!this.policy) return '';
        console.log
        switch (this.policyPremiumType) {
            case 'submitted': return this.policy.submittedPremium;
            case 'paid': return this.policy.paidPremium;
            default: return '';
        }
    }

    addComment(ev, policy) {
        ev.stopPropagation();
        ev.preventDefault();
        this.policy = policy;
        this.showAddPolicyNote = true;
        this.showDrawer = moment().format();
    }

    closePolicyNote() {
        this.policy.mostRecentNoteDate = moment().toISOString();
        this.showDrawer = undefined;
        this.showAddPolicyNote = false;
    }

    policyNoteUpdated(policy) {
        policy.mostRecentNoteDate = moment().toISOString();
    }

    toggleMiniPolicySummary(ev, policy) {
        ev.stopPropagation();
        ev.preventDefault();
        if (Capacitor.isNativePlatform()) {
            this.showPolicyDetails(policy);
            return;
        }
        policy.showMiniSummary = !policy.showMiniSummary;
        return false;
    }

    showPolicyDetails(policy) {
		this._ea.publish(c.EventKeys.site.openPolicy, { policyId: policy.id });
    }

    drawerClosed() {
        this.policy = undefined;
        this.showAddPolicyNote = false;
    }
}
